import { IInstallationSettingsCard } from "../../interfaces/interfaces";
import { ReactComponent as Arrow } from "../../img/Arrow.svg";

const InstallationSettingsCard: React.FC<{
  data: IInstallationSettingsCard;
  openModalFn: () => void;
  children?: React.ReactNode;
}> = ({ data, openModalFn, children }) => {
  const { settingName } = data;
  return (
    <div
      className="brilliant-card-container brilliant-card-installation"
      onClick={openModalFn}
      onKeyDown={openModalFn}
      role="button"
      tabIndex={0}
    >
      <div className="brilliant-card-description">
        <div
          data-testid={`installation-setting-name-${settingName.replace(
            / /g,
            ""
          )}`}
          className="unit-title"
        >
          {settingName}
        </div>
        {children}
      </div>
      <Arrow className="brilliant-card-installation-icon" />
    </div>
  );
};
export default InstallationSettingsCard;
