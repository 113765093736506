// Learn more links for alerts:
export const LEARN_MORE_CLIMATE_ISSUE =
  "https://support.brilliant.tech/hc/en-us/articles/13499999515291";
export const LEARN_MORE_LEAK_DETECTED =
  "https://support.brilliant.tech/hc/en-us/articles/13499907961627";
export const LEARN_MORE_RESIDENT_INVITE_FAILED =
  "https://support.brilliant.tech/hc/en-us/articles/15673155688731";
export const LEARN_MORE_UNIT_RECONFIGURATION_ISSUE =
  "https://support.brilliant.tech/hc/en-us/articles/15807618915099";
export const LEARN_MORE_LOW_BATTERY =
  "https://support.brilliant.tech/hc/en-us/articles/22407370267803-Low-Battery";

// Tenant links
export const LEARN_MORE_INSTALL_INCOMPLETE =
  "https://support.brilliant.tech/hc/en-us/articles/13499344203163";
export const LEARN_MORE_MOVE_IN =
  "https://support.brilliant.tech/hc/en-us/articles/13500508851867";
export const LEARN_MORE_MOVE_OUT =
  "https://support.brilliant.tech/hc/en-us/articles/13500559509787";

// Entrata links
export const LEARN_MORE_ENTRATA =
  "https://support.brilliant.tech/hc/en-us/articles/13657987701275";

// RealPage links
export const LEARN_MORE_REALPAGE =
  "https://support.brilliant.tech/hc/en-us/articles/16412407716891";

// ResetInstallation links
export const LEARN_MORE_RESET_INSTALLATION =
  "https://support.brilliant.tech/hc/en-us/articles/18399207400347";
