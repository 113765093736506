import { ReactComponent as Trash } from "../img/Trash.svg";
import { ReactComponent as Gear } from "../img/settings.svg";
import { IInvitedUser } from "../interfaces/interfaces";
import { dateTimeTimeZoneString } from "../utils/utils";
import { selectedUserToShowVar } from "../components/apollo/LocalState";

const InvitedUserCard: React.FC<{
  invitedUser: IInvitedUser;
  openRemoveFlowModal: (user: IInvitedUser) => void;
  renderDeleteButtonAsIcon?: boolean;
  shouldGearRender: boolean;
  openEditResidentModal?: () => void;
}> = ({
  invitedUser,
  openRemoveFlowModal = () => undefined,
  renderDeleteButtonAsIcon = true,
  shouldGearRender,
  openEditResidentModal = () => undefined,
}) => {
  const expirationDatetime = new Date(invitedUser.expirationDatetime);
  const invitationExpired = expirationDatetime.getTime() <= Date.now();

  return (
    <div className="user-card-container">
      <div className="user-card-entry user-card-entry-container">
        <div>
          <div className="user-card-entry-header text-label">Email</div>
          <div className="user-card-entry-body placeholder">
            {invitedUser.emailAddress}
          </div>
        </div>
        <button
          type="button"
          className="table-cell-trash"
          onClick={() => {
            openRemoveFlowModal(invitedUser);
          }}
        >
          {renderDeleteButtonAsIcon && (
            <Trash title="Remove user" data-testid="trash-icon" />
          )}
          {shouldGearRender && (
            <Gear
              onClick={() => {
                openEditResidentModal();
                selectedUserToShowVar(invitedUser);
              }}
              title="Edit user"
              data-testid="gear-icon"
            />
          )}
        </button>
      </div>
      <div className="user-card-entry">
        <div className="user-card-entry-header text-label">Status</div>
        {invitationExpired ? (
          <div
            className="user-card-entry-body user-card-entry-red placeholder"
            data-testid="expired-invitation"
          >
            Invite expired{" "}
            {dateTimeTimeZoneString(invitedUser.expirationDatetime)}
          </div>
        ) : (
          <div className="user-card-entry-body user-card-entry-blue placeholder">
            Invite expires{" "}
            {dateTimeTimeZoneString(invitedUser.expirationDatetime)}
          </div>
        )}
      </div>
    </div>
  );
};

export default InvitedUserCard;
