import { useQuery } from "@apollo/client";
import { connect } from "react-redux";

import { ReactComponent as CloseIcon } from "../../img/Close.svg";
import { AlertTypes, alertImage, alertTitle } from "../../utils/alertUtils";
import Spinner from "../Spinner";
import { GET_INTEGRATION_TYPES_FOR_ORGANIZATION } from "../../api/gqlQueries";
import {
  IAppState,
  IOrganizationData,
  IOrganizationParams,
} from "../../interfaces/interfaces";
import {
  checkIntegrations,
  renderIntegrationInfo,
} from "../../utils/integrationUtils";
import {
  LEARN_MORE_CLIMATE_ISSUE,
  LEARN_MORE_LEAK_DETECTED,
  LEARN_MORE_LOW_BATTERY,
  LEARN_MORE_RESIDENT_INVITE_FAILED,
  LEARN_MORE_UNIT_RECONFIGURATION_ISSUE,
} from "../../utils/LearnMoreLinks";

const renderLeakDetected = (
  buildingName: string,
  unitName: string,
  deviceName: string
) => {
  return (
    <div className="menu-light menu alert-popup-description-text">
      A leak has been detected in
      <br />
      <b>
        {buildingName} / {unitName} / {deviceName}.
      </b>
      <br />
      Please go to the unit to resolve the issue.
    </div>
  );
};

const renderExtremeTemperature = (
  buildingName: string,
  unitName: string,
  deviceName: string
) => {
  return (
    <div className="menu-light menu alert-popup-description-text">
      The thermostat in
      <br />
      <b>
        {buildingName} / {unitName} / {deviceName}
      </b>
      <br />
      is detecting a temperature that is outside of the normal range. Please go
      to the unit to resolve the issue.
    </div>
  );
};

const renderLowBattery = ({
  buildingName,
  unitName,
  deviceName,
  deviceType,
}: {
  buildingName: string;
  unitName: string;
  deviceName: string;
  deviceType?: string;
}) => {
  return (
    <div className="menu-light menu alert-popup-description-text">
      The batteries are running low for the {deviceType} <b>{deviceName}</b> in{" "}
      <b>
        {unitName} - {buildingName}
      </b>
      .
      <br />
      <br />
      Please go to the unit and replace the device&apos;s batteries.
    </div>
  );
};

const renderFailedToSendInvite = ({ pmsName }: { pmsName: string }) => {
  return (
    <div className="menu-light menu alert-popup-description-text">
      Brilliant failed to invite residents added in {pmsName} because the
      unit&apos;s installation has not been marked complete.
      <br />
      <br />
      Please mark the unit&apos;s installation as complete from the Brilliant
      mobile app so that Brilliant can invite the residents to join Brilliant.
    </div>
  );
};

const renderReconfigurationIssue = ({
  reconfiguringDeviceNames,
}: {
  reconfiguringDeviceNames?: string[];
}) => {
  return (
    <div className="menu-light menu alert-popup-description-text">
      {reconfiguringDeviceNames ? (
        <div data-testid="alert-modal-reconfiguring-devices">
          The Brilliant devices listed below are taking much longer than
          expected to finish reconfiguring:
          <br />
          <br />
          <b>{reconfiguringDeviceNames.join(", ")}</b>
          <br />
        </div>
      ) : (
        <div data-testid="alert-modal-generic-reconfiguration-message">
          One or more of this unit&apos;s Brilliant devices are taking much
          longer than expected to finish reconfiguring.
          <br />
        </div>
      )}
      <br />
      Please make sure the Brilliant devices are online and/or the unit&apos;s
      backup WiFi network is available so that Brilliant can finish
      reconfiguring this unit&apos;s Brilliant Home.
    </div>
  );
};

function getLearnMoreLink(alarmType: string) {
  switch (alarmType) {
    case AlertTypes.LeakDetected:
      return LEARN_MORE_LEAK_DETECTED;
    case AlertTypes.ExtremeTemperature:
      return LEARN_MORE_CLIMATE_ISSUE;
    case AlertTypes.ResidentInviteFailed:
      return LEARN_MORE_RESIDENT_INVITE_FAILED;
    case AlertTypes.ReconfigurationTakingTooLong:
      return LEARN_MORE_UNIT_RECONFIGURATION_ISSUE;
    case AlertTypes.LowBattery:
      return LEARN_MORE_LOW_BATTERY;
    default:
      return null;
  }
}

export interface AlertModalProps {
  closeAlertModal: () => void;
  alarmType: string;
  buildingName: string;
  unitName: string;
  deviceName: string;
  organizationId: string;
  ambientTemperatureF?: number;
  reconfiguringDeviceNames?: string[];
  deviceType?: string;
}

const AlertModal: React.FC<AlertModalProps> = ({
  closeAlertModal,
  alarmType,
  buildingName,
  unitName,
  deviceName,
  ambientTemperatureF,
  organizationId,
  reconfiguringDeviceNames,
  deviceType,
}) => {
  const {
    loading: organizationIntegrationsLoading,
    data: organizationIntegrations,
  } = useQuery<IOrganizationData, IOrganizationParams>(
    GET_INTEGRATION_TYPES_FOR_ORGANIZATION,
    {
      variables: { organizationId },
    }
  );

  if (organizationIntegrationsLoading) {
    return <Spinner />;
  }
  if (!organizationIntegrations) {
    throw Error("No data was returned");
  }
  const integrationType = checkIntegrations(
    organizationIntegrations.organization.integrations
  );
  const integrationNameLink = renderIntegrationInfo({ integrationType });
  const learnMoreLink = getLearnMoreLink(alarmType);
  return (
    <div className="popup-background">
      <div
        className="alert-popup-container"
        data-testid="alert-popup-container"
      >
        <div className="alert-popup-title-container">
          <div data-testid="alert-modal-title" className="unit-title">
            {alertTitle[alarmType]}
          </div>
          <CloseIcon
            title="close"
            className="alert-popup-title-close"
            onClick={closeAlertModal}
          />
        </div>
        <div className="alert-popup-description-container center-align-as-column">
          <div className="center-align-as-row">
            {alertImage(alarmType, true)}
            {ambientTemperatureF && (
              <h3 className="alert-popup-description-title">
                {ambientTemperatureF}°F
              </h3>
            )}
          </div>
          {alarmType === AlertTypes.ExtremeTemperature &&
            renderExtremeTemperature(buildingName, unitName, deviceName)}
          {alarmType === AlertTypes.LeakDetected &&
            renderLeakDetected(buildingName, unitName, deviceName)}
          {alarmType === AlertTypes.ResidentInviteFailed &&
            renderFailedToSendInvite({ pmsName: integrationNameLink.name })}
          {alarmType === AlertTypes.ReconfigurationTakingTooLong &&
            renderReconfigurationIssue({
              reconfiguringDeviceNames,
            })}
          {alarmType === AlertTypes.LowBattery &&
            renderLowBattery({
              buildingName,
              deviceName,
              deviceType,
              unitName,
            })}
          {learnMoreLink && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              type="button"
              className="secondary-btn menu alert-popup-description-btn"
              href={learnMoreLink}
            >
              Learn More
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    organizationId: state.user.organizationId,
  };
};

export default connect(mapStateToProps)(AlertModal);
