import { IBrilliantPlugCard } from "../../interfaces/interfaces";
import { ReactComponent as Plug } from "../../img/Devices/Plug.svg";

import BrilliantDeviceCard from "./BrilliantDeviceCard";

const plugIcon = (isReconfiguring: boolean) => {
  return (
    <Plug
      title="Smart Plug"
      className={
        isReconfiguring
          ? "brilliant-card-img-left brilliant-card-img-stroke disabled"
          : "brilliant-card-img-left brilliant-card-img-stroke"
      }
    />
  );
};

const BrilliantPlugCard: React.FC<{
  data: IBrilliantPlugCard;
}> = ({ data }) => {
  return (
    <BrilliantDeviceCard
      id={data.id}
      name={data.name}
      description="Smart Plug"
      isReconfiguring={data.isReconfiguring}
      icon={plugIcon(data.isReconfiguring)}
    />
  );
};
export default BrilliantPlugCard;
