import { Link } from "react-router-dom";

import { IConfigurationData } from "../../interfaces/interfaces";
import { ReactComponent as Settings } from "../../img/settings.svg";

const ConfigurationsCard: React.FC<{ configuration: IConfigurationData }> = ({
  configuration,
}) => {
  return (
    <Link
      to={`/configurations/${configuration.id}`}
      className="configuration-card-container"
    >
      <div className="configuration-card-line">
        <div className="unit-title">{configuration.title}</div>
        <Settings title="Settings" className="configuration-card-line-img" />
      </div>
    </Link>
  );
};

export default ConfigurationsCard;
