import { connect } from "react-redux";

import LoginResultModal from "../../modals/LoginResultModal";
import { ReactComponent as WarningSign } from "../../../img/WarningSign.svg";
import {
  EEntrataError,
  IAppState,
  IObjectIndexType,
} from "../../../interfaces/interfaces";
import history from "../../../history";

export const renderLogo = (): JSX.Element => {
  return (
    <div className="center-align-as-row">
      <WarningSign className="warning-icon" />
    </div>
  );
};

interface IEntrataLinkingProps {
  entrataError: EEntrataError;
}

const errorTitle: IObjectIndexType = {
  notAuthorized: "You Need Permission",
  unknownError: "Something Went Wrong",
  upstreamError: "Connection Error",
};
const errorMessage: { [key: string]: JSX.Element } = {
  notAuthorized: (
    <>
      You are not authorized to link Entrata to your Brilliant organization.
      <br />
      <br />
      Please contact your organization&apos;s administrator to resolve this
      issue.
    </>
  ),
  unknownError: (
    <>
      There was an error linking Brilliant with Entrata.
      <br />
      <br />
      Please try again later.
    </>
  ),
  upstreamError: (
    <>
      Brilliant was unable to communicate with Entrata to link your accounts.
      <br />
      <br />
      Please try again later. If the issue persists, contact Entrata for
      support.
    </>
  ),
};

const EntrataError: React.FC<IEntrataLinkingProps> = ({ entrataError }) => {
  return (
    <LoginResultModal
      logo={renderLogo()}
      title={errorTitle[EEntrataError[entrataError]]}
      actionBtnTitle="Back to Login"
      actionBtnAction={() => history.goBack()}
    >
      {errorMessage[EEntrataError[entrataError]]}
    </LoginResultModal>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    entrataError: state.entrataData.error,
  };
};
export default connect(mapStateToProps)(EntrataError);
