import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import {
  ERegistrationError,
  IAppState,
  IRegistrationData,
} from "../../interfaces/interfaces";
import { getQueryParams, sendVerificationEmail } from "../../actions";
import UserRegistrationError from "../errors/UserRegistrationError";

import TermsAndConditions from "./TermsAndConditions";
import CompleteAccountStep from "./common/CompleteAccountStep";
import EmailVerificationStep from "./common/EmailVerificationStep";
import InputMFAPhoneNumberStep from "./common/InputMFAPhoneNumberStep";
import EmailVerificationForm from "./EmailVerificationForm";
import ExpiredInvitation from "./ExpiredInvitation";
import PhoneVerificationStep from "./PhoneVerificationStep";
import Success from "./Success";
import CompleteAccountForm from "./CompleteAccountForm";
import InputMFAPhoneNumberForm from "./InputMFAPhoneNumberForm";

const Registration: React.FC<{ newUser: IRegistrationData }> = ({
  newUser,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQueryParams());
    dispatch(sendVerificationEmail({}));
  }, [dispatch]);

  if (newUser.error === ERegistrationError.expiredInvitation)
    return <ExpiredInvitation />;

  if (!newUser.emailVerified)
    return (
      <EmailVerificationStep
        form={<EmailVerificationForm />}
        email={newUser.email}
      />
    );
  if (!newUser.termsAccepted) return <TermsAndConditions />;
  // If the user exists, the email verification step will set the userId
  // If the user does not exist, this will be unset.
  if (!newUser.userId)
    return <CompleteAccountStep form={<CompleteAccountForm />} />;

  // If the user already exists, the phone number will not be set yet.
  if (!newUser.phoneNumber)
    // Get the phone number for MFA
    return <InputMFAPhoneNumberStep form={<InputMFAPhoneNumberForm />} />;

  // If the number has not been verified yet, verify it.
  if (!newUser.phoneVerified) return <PhoneVerificationStep />;

  // If everything has been completed, show the success page.
  if (newUser.phoneVerified && newUser.accountCompleted) return <Success />;
  return (
    <UserRegistrationError
      description="There was a problem giving you access to Smart Building Administration. If the problem persists, please contact Brilliant."
      name="Something went wrong"
    />
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    newUser: state.newUser,
  };
};

export default connect(mapStateToProps)(Registration);
