import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { useEffect, useState } from "react";

import {
  GET_ALERT_NOTIFICATION_SETTINGS,
  GET_INTEGRATION_TYPES_FOR_ORGANIZATION,
  GET_ORGANIZATION_BUILDINGS,
} from "../../../api/gqlQueries";
import {
  IAlarmNotificationSetting,
  IAlertNotificationsParams,
  IAppState,
  IOrganizationData,
  IOrganizationParams,
  IPropertyData,
  IUserDataWithAccountInfo,
} from "../../../interfaces/interfaces";
import {
  hasPMSIntegration,
  sortPropertiesByName,
} from "../../../utils/integrationUtils";
import {
  AlertTypes,
  assetProtectionAlerts,
  residentTransitionAlerts,
} from "../../../utils/alertUtils";
import Spinner from "../../Spinner";
import { ReactComponent as Bell } from "../../../img/Bell.svg";
import LearnMoreButton from "../../buttons/LearnMoreButton";
import BuildingDropdown from "../../../utils/buildingDropdown";
import { ReactComponent as TextMessage } from "../../../img/text-message.svg";
import { ReactComponent as Mail } from "../../../img/mail.svg";
import { NotificationMethod } from "../../../utils/notificationUtils";

import GroupedAlertPreferences from "./GroupedAlertPreferences";

const AlertPreferencesPageBody: React.FC<{
  userId: string;
  propertyId: string;
  hasPMS: boolean;
}> = ({ userId, propertyId, hasPMS }) => {
  const { data: buildingData, loading } = useQuery<
    IUserDataWithAccountInfo,
    IAlertNotificationsParams
  >(GET_ALERT_NOTIFICATION_SETTINGS, { variables: { propertyId, userId } });
  if (!buildingData) return null;
  if (loading) return <Spinner />;
  const allAlarmNotificationSettings = Object.values(AlertTypes)
    .filter((alertType) => {
      switch (alertType) {
        case AlertTypes.ExtremeTemperature:
        case AlertTypes.LeakDetected:
        case AlertTypes.ReconfigurationTakingTooLong:
        case AlertTypes.LowBattery:
          return true;
        case AlertTypes.ResidentInviteFailed:
          return hasPMS;
        default:
          return false;
      }
    })
    .map((alertType) => {
      const existingAlarmSetting =
        buildingData.user.alarmNotificationSettings.find(
          (element) => element.alarmType === alertType
        );
      if (!existingAlarmSetting)
        return {
          alarmType: alertType,
          notificationSettings: [
            { enabled: false, notificationMethod: NotificationMethod.Email },
            { enabled: false, notificationMethod: NotificationMethod.Sms },
          ],
        } as IAlarmNotificationSetting;
      return existingAlarmSetting;
    });
  const assetProtectionNotifications = allAlarmNotificationSettings.filter(
    (setting) => assetProtectionAlerts.includes(setting.alarmType)
  );
  const residentTransitionNotifications = allAlarmNotificationSettings.filter(
    (setting) => residentTransitionAlerts.includes(setting.alarmType)
  );
  return (
    <>
      {assetProtectionNotifications.length > 0 && (
        <GroupedAlertPreferences
          name="Asset Protection"
          propertyId={propertyId}
          alarmNotificationSettings={assetProtectionNotifications}
        />
      )}
      {residentTransitionNotifications.length > 0 && (
        <GroupedAlertPreferences
          name="Resident Transitions"
          propertyId={propertyId}
          alarmNotificationSettings={residentTransitionNotifications}
        />
      )}
    </>
  );
};

interface IOrganizationBuildings {
  organization: {
    buildings: IPropertyData[];
  };
}

export interface AlertPreferencesLandingPageProps {
  userId: string;
  organizationId: string;
  match: { params: { buildingId: string }; path: string };
}

const AlertPreferencesLandingPage: React.FC<
  AlertPreferencesLandingPageProps
> = ({ userId, organizationId, match }) => {
  const { buildingId } = match.params;
  const [selectedBuildingId, setSelectedBuildingId] = useState<string>("");
  const { loading: organizationLoading, data: organizationData } = useQuery<
    IOrganizationBuildings,
    IOrganizationParams
  >(GET_ORGANIZATION_BUILDINGS, { variables: { organizationId } });
  const {
    loading: organizationIntegrationsLoading,
    data: organizationIntegrations,
  } = useQuery<IOrganizationData, IOrganizationParams>(
    GET_INTEGRATION_TYPES_FOR_ORGANIZATION,
    {
      variables: { organizationId },
    }
  );
  let sortedBuildings: IPropertyData[] = [];
  if (organizationData)
    sortedBuildings = sortPropertiesByName(
      organizationData.organization.buildings
    );
  useEffect(() => {
    if (sortedBuildings.length !== 0 && !buildingId)
      setSelectedBuildingId(sortedBuildings[0].id);
    if (sortedBuildings.length !== 0 && buildingId)
      setSelectedBuildingId(buildingId);
  }, [organizationData]);
  if (organizationLoading || organizationIntegrationsLoading)
    return <Spinner />;
  if (!organizationData || !organizationIntegrations) return null;
  const hasPMS = hasPMSIntegration(
    organizationIntegrations.organization.integrations
  );
  return (
    <div className="grouped-preferences-main-container">
      <div className="grouped-preferences-main-header">
        <Bell className="grouped-preferences-main-header-svg" title="Bell" />
        <div className="unit-title">Alert Preferences</div>
      </div>
      <div className="grouped-preferences-main-link">
        <p>
          Manage how Brilliant notifies you about alerts at your properties.{" "}
        </p>
        <LearnMoreButton
          link="https://support.brilliant.tech/hc/en-us/articles/13499857157019"
          text="Learn More"
        />
      </div>
      <div className="grouped-preferences-building-container">
        <BuildingDropdown
          sortedBuildings={sortedBuildings}
          match={match}
          setSelectedBuildingId={setSelectedBuildingId}
          selectedBuildingId={selectedBuildingId}
        />
        <div className="grouped-preferences-building-icons">
          <div className="grouped-preferences-building-icon">
            <Mail />
            <div className="text-label">Email</div>
          </div>
          <div className="grouped-preferences-building-icon">
            <TextMessage />
            <div className="text-label">Text</div>
          </div>
        </div>
      </div>
      {selectedBuildingId && (
        <AlertPreferencesPageBody
          userId={userId}
          propertyId={selectedBuildingId}
          hasPMS={hasPMS}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    organizationId: state.user.organizationId,
    userId: state.user.userId,
  };
};

export default connect(mapStateToProps)(AlertPreferencesLandingPage);
