import {
  getClimateAlertNames,
  getWarningNames,
} from "../../../utils/alertUtils";
import {
  renderSideLine,
  renderTags,
  renderUnitDetailsMainBtn,
} from "../../../utils/utils";
import { ReactComponent as Key } from "../../../img/key.svg";
import { IUnitDetailsDataHome } from "../../../interfaces/interfaces";
import { renderUnitDetailsElementAmount } from "../common/CommonUnitDetailsComponents";

export interface UnitDetailsMainInfoForLargeScreenProps {
  home: IUnitDetailsDataHome;
  totalTenants: number;
  numBrilliantDevices: number;
  numIntegrations: number;
  setShowInviteTenantModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReconfigurationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setInviteTenantCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setShowMoveOutModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCancelMoveInModal: React.Dispatch<React.SetStateAction<boolean>>;
  hasIntegration: boolean;
  isInReconfigState: boolean;
}

const UnitDetailsMainInfoForLargeScreen: React.FC<
  UnitDetailsMainInfoForLargeScreenProps
> = ({
  home,
  setShowInviteTenantModal,
  totalTenants,
  numBrilliantDevices,
  numIntegrations,
  setInviteTenantCurrentStep,
  setShowMoveOutModal,
  setShowReconfigurationModal,
  setShowCancelMoveInModal,
  hasIntegration,
  isInReconfigState,
}) => {
  const isOccupiedUnit = home.numResidents > 0;
  const isInvitedUnit = home.numInvitedResidents > 0;
  const occupancyClass = isOccupiedUnit
    ? "unit-status-occupied"
    : "unit-status-vacant";
  const occupancyString = isOccupiedUnit ? "Occupied" : "Vacant";
  const alertDetected = home.alerts.length !== 0;
  const alerts = getClimateAlertNames(home.alerts);
  const warningNames = getWarningNames(home.warnings);
  const hasWarnings = warningNames.length > 0;
  return (
    <div className="unit-main-container">
      {renderSideLine({
        hasAlerts: alertDetected,
        hasWarnings,
        lineClassName: "unit-main-container-line",
      })}

      <div className="flex-display">
        <div className="unit-names">
          <Key title="Key" className="unit-names-img" />
          <div className="unit-names-info">
            <div className="unit-names-info-name unit-title">
              {home.propertyName}
            </div>
            <div className="unit-description margin-bottom-very-small">
              {home.parentProperties[0].name}
            </div>
          </div>
        </div>
        <div className="unit-details-container-right">
          <div className="unit-details-container unit-description-small unit-description-small-info">
            <div className="unit-details">
              {renderUnitDetailsElementAmount({
                nameOfSingleDevice: "Brilliant",
                numDevices: numBrilliantDevices,
              })}
            </div>
            <div className="unit-details">
              {renderUnitDetailsElementAmount({
                nameOfSingleDevice: "Integration",
                numDevices: numIntegrations,
              })}
            </div>
            <div className="unit-details">
              {renderUnitDetailsElementAmount({
                nameOfSingleDevice: "Resident",
                numDevices: totalTenants,
              })}
            </div>
          </div>
          <div className="unit-details-button">
            {renderUnitDetailsMainBtn({
              hasIntegration,
              isInReconfigState,
              isInvitedUnit,
              isOccupiedUnit,
              isSmallScreen: false,
              isVacantUnit: false,
              setInviteTenantCurrentStep,
              setShowCancelMoveInModal,
              setShowMoveInModal: setShowInviteTenantModal,
              setShowMoveOutModal,
              setShowReconfigurationModal,
            })}
          </div>
        </div>
      </div>
      <div className="center-align-as-row margin-left-medium">
        <div className={`unit-status unit-tag ${occupancyClass}`}>
          {occupancyString}
        </div>
        {renderTags({ alerts, warnings: warningNames })}
      </div>
    </div>
  );
};

export default UnitDetailsMainInfoForLargeScreen;
