import { gql } from "@apollo/client";

export const ADD_REMOTELOCK_DEVICES_TO_PROPERTIES_GQL =
  "addRemotelockDevicesToProperties";
export const REMOVE_REMOTELOCK_DEVICES_TO_PROPERTIES_GQL =
  "removeRemotelockDevicesFromProperties";
export const RESET_INSTALLATION_GQL = "resetUnitInstallationState";
export const UPDATE_CARD_CREDENTIALS_SQL = "updateCardCredentials";
export const GET_REMOTELOCK_BUILDING_GQL = "GetRemoteLockBuilding";
export const GET_DOOR_GROUPS_AND_UNIT_ACCESS_DEVICES_FOR_HOME_GQL =
  "GetDoorGroups";
export const GET_INTEGRATIONS_FOR_ORGANIZATION_GQL =
  "GetIntegrationsForOrganization";
export const GET_INTEGRATION_TYPE_FOR_ORGANIZATION_GQL =
  "GetIntegrationTypesForOrganization";
export const UPDATE_USER_NOTIFICATION_SETTINGS_SQL_MUTATION =
  "updateUserPropertyNotificationSetting";

const EXTREME_TEMPERATURE_FRAGMENT = gql`
  fragment ExtremeTemperatureFragment on ExtremeTemperatureClimateAlarm {
    ambientTemperatureF
  }
`;

const LOW_BATTERY_FRAGMENT = gql`
  fragment LowBatteryFragment on LowBatteryAlert {
    deviceType
  }
`;

const HOME_ALERTS_FRAGMENT = gql`
  ${EXTREME_TEMPERATURE_FRAGMENT}
  ${LOW_BATTERY_FRAGMENT}
  fragment HomeAlertsFragment on Home {
    alerts {
      id
      alarmType
      deviceName
      building {
        id
        propertyName
      }
      home {
        id
        propertyName
      }
      ...ExtremeTemperatureFragment
      ...LowBatteryFragment
    }
  }
`;

const HOME_FRAGMENT = gql`
  fragment HomeFragment on Home {
    homeId
    propertyName
    numResidents
    numInvitedResidents
    parentProperties {
      propertyId
      name
    }
    integrations {
      name
      numDevices
    }
    brilliantControls {
      id
      name
      numGangs
    }
    brilliantSwitches {
      id
      name
    }
    brilliantPlugs {
      id
      name
    }
    installationDetails {
      installedBrilliantControls {
        id
        name
        numGangs
      }
      installedBrilliantPlugs {
        id
        name
      }
      installedBrilliantSwitches {
        id
        name
      }
      installedIntegrationDevices {
        id
        name
        integrationType
      }
    }
    numRemotelockDevices
    reconfigurationState
    scheduledConfigurations {
      configurationTitle
      scheduledDatetime
      id
      createdDatetime
    }
    wifiCredentials {
      networkName
      networkType
      password
      securityType
    }
    isInstallIncomplete
    warnings @client {
      warningType
    }
  }
`;

const REMOTE_LOCK_AVAILABLE_ACCESS_DEVICES_FRAGMENT = gql`
  fragment RemoteLockAvailableAccessDevicesFragment on RemoteLock {
    devices {
      id
      name
      accessibleType
    }
    doorGroups {
      accessibleType
      id
      name
      numDevices
    }
  }
`;

export const GET_HOME = gql`
  ${HOME_FRAGMENT}
  ${HOME_ALERTS_FRAGMENT}
  query GetHome($propertyId: String!) {
    home(propertyId: $propertyId) {
      id
      ...HomeFragment
      ...HomeAlertsFragment @client
    }
  }
`;

export const GET_BUILDING = gql`
  query GetBuilding($buildingId: String!) {
    building(buildingId: $buildingId) {
      id
      propertyName
      addressLine1
      addressLine2
      city
      state
      zipcode
      homes {
        id
        alerts @client {
          id
          alarmType
        }
        isInstallIncomplete
        warnings @client {
          warningType
        }
        propertyName
        reconfigurationState
        floor
        numBrilliantControls
        numBrilliantPlugs
        numBrilliantSwitches
        numIntegrations
        numResidents
        numInvitedResidents
      }
    }
  }
`;

export const GET_REMOTE_LOCK_BUILDING = gql`
  query ${GET_REMOTELOCK_BUILDING_GQL}($buildingId: String!) {
    building(buildingId: $buildingId) {
      id
      homes {
        id
        propertyName
        remotelockDoorGroups {
          id
          doorGroup {
            id
            accessibleType
            name
            numDevices
          }
        }
        remotelockUnitAccessDevices {
          id
          device {
            id
            name
            accessibleType
          }
        }
      }
    }
  }
`;

export const GET_DOOR_GROUPS_AND_UNIT_ACCESS_DEVICES_FOR_HOME = gql`
  query ${GET_DOOR_GROUPS_AND_UNIT_ACCESS_DEVICES_FOR_HOME_GQL}($propertyId: String!) {
    home(propertyId: $propertyId) {
      id
      remotelockDoorGroups {
        id
        doorGroup {
          id
        }
      }
      remotelockUnitAccessDevices {
        id
        device {
          id
          accessibleType
        }
      }
    }
  }
`;

export const GET_RESIDENT_INFO = gql`
  query GetResidentInfo($propertyId: String!) {
    home(propertyId: $propertyId) {
      id
      residents {
        id
        familyName
        givenName
        emailAddress
        joinedPropertyDatetime
        deletedDatetime
      }
      invitedResidents {
        id
        emailAddress
        expirationDatetime
      }
    }
  }
`;

export const GET_RESIDENT_CARD_INFO = gql`
  query GetResidentCardInfo($propertyId: String!, $userId: String!) {
    resident(propertyId: $propertyId, userId: $userId) {
      id
      remotelockCardNumber
      smartCardCredentials {
        id
        badgeNumber
      }
    }
  }
`;

export const GET_INVITED_RESIDENT_CARD_INFO = gql`
  query GetInvitedResidentCardInfo(
    $propertyId: String!
    $emailAddress: String!
  ) {
    invitedResident(propertyId: $propertyId, emailAddress: $emailAddress) {
      id
      remotelockCardNumber
      smartCardCredentials {
        id
        badgeNumber
      }
    }
  }
`;

export const GET_INVITED_RESIDENT_EMAILS = gql`
  query GetInvitedResidentEmails($propertyId: String!) {
    home(propertyId: $propertyId) {
      id
      invitedResidents {
        emailAddress
      }
    }
  }
`;

export const GET_PORTAL_USERS_INFO = gql`
  query GetPortalUsersInfo($organizationId: String!) {
    organization(organizationId: $organizationId) {
      id
      name
      portalUsers {
        id
        emailAddress
        givenName
        familyName
        joinedPropertyDatetime
      }
      invitedPortalUsers {
        id
        emailAddress
        expirationDatetime
      }
    }
  }
`;

export const GET_CONFIGURATIONS = gql`
  query GetConfigurations {
    configurations {
      id
      title
    }
  }
`;

export const GET_CONFIGURATION_DETAILS = gql`
  query GetConfigurationDetails($id: String!) {
    configuration(id: $id) {
      id
      title
      description
    }
  }
`;

export const GET_USER = gql`
  query GetUser($userId: String!) {
    user(userId: $userId) {
      id
      familyName
      givenName
      birthdate
    }
  }
`;

export const GET_USER_ACCOUNT_INFO = gql`
  query GetUser($userId: String!) {
    user(userId: $userId) {
      id
      familyName
      givenName
      birthdate
      mfaPhoneNumber
    }
  }
`;

export const GET_ALERT_NOTIFICATION_SETTINGS = gql`
  query GetAlertNotificationSettings($userId: String!, $propertyId: String!) {
    user(userId: $userId) {
      id
      alarmNotificationSettings(propertyId: $propertyId) {
        alarmType
        notificationSettings {
          notificationMethod
          enabled
        }
      }
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query GetOrganization($organizationId: String!) {
    organization(organizationId: $organizationId) {
      id
      name
    }
  }
`;

export const GET_INTEGRATION_TYPES_FOR_ORGANIZATION = gql`
  query GetIntegrationTypesForOrganization($organizationId: String!) {
    organization(organizationId: $organizationId) {
      id
      integrations {
        integrationType
      }
    }
  }
`;

export const GET_INTEGRATIONS_FOR_ORGANIZATION = gql`
  ${REMOTE_LOCK_AVAILABLE_ACCESS_DEVICES_FRAGMENT}
  query GetIntegrationsForOrganization($organizationId: String!) {
    organization(organizationId: $organizationId) {
      id
      integrations {
        integrationType
        ...RemoteLockAvailableAccessDevicesFragment
      }
    }
  }
`;

export const GET_SMART_CARD_NUMBERS = gql`
  query GetSmartCardNumbers($organizationId: String!) {
    organization(organizationId: $organizationId) {
      id
      smartCardCredentialsV2 {
        id
        badgeNumber
      }
    }
  }
`;

export const GET_ORGANIZATION_SUMMARY = gql`
  query GetOrganizationSummary($organizationId: String!) {
    organization(organizationId: $organizationId) {
      id
      buildings {
        id
        addressLine1
        addressLine2
        city
        propertyName
        state
        zipcode
      }
      numHomes
    }
  }
`;

export const GET_ORGANIZATION_BUILDINGS = gql`
  query GetOrganizationBuildings($organizationId: String!) {
    organization(organizationId: $organizationId) {
      id
      buildings {
        id
        addressLine1
        addressLine2
        city
        propertyName
        state
        zipcode
      }
    }
  }
`;

export const GET_ALERTS_FOR_ORGANIZATION = gql`
  ${EXTREME_TEMPERATURE_FRAGMENT}
  ${LOW_BATTERY_FRAGMENT}
  query GetAlertsForOrganization($organizationId: String!) {
    organization(organizationId: $organizationId) {
      id
      alerts {
        id
        alarmType
        deviceName
        building {
          id
          propertyName
        }
        home {
          id
          propertyName
        }
        ...ExtremeTemperatureFragment
        ...LowBatteryFragment
      }
    }
  }
`;

export const UPDATE_WIFI_CREDENTIALS = gql`
  mutation UpdateWifiCredentials(
    $propertyId: String!
    $networkName: String!
    $networkType: String!
    $password: String!
    $securityType: String
  ) {
    updateWifiCredentials(
      input: {
        propertyId: $propertyId
        networkName: $networkName
        networkType: $networkType
        password: $password
        securityType: $securityType
      }
    ) {
      wifiCredentials {
        networkName
        networkType
        password
        securityType
      }
    }
  }
`;

export const RESET_INSTALLATION = gql`
  ${HOME_FRAGMENT}
  ${HOME_ALERTS_FRAGMENT}
  mutation ${RESET_INSTALLATION_GQL}(
    $propertyId: String!
  ) {
    ${RESET_INSTALLATION_GQL}(
      input: {
        propertyId: $propertyId
      }
    ) {
      home {
        id
        ...HomeFragment
        ...HomeAlertsFragment
      }
    }
  }
  `;

export const UPDATE_CARD_CREDENTIALS = gql`
  mutation ${UPDATE_CARD_CREDENTIALS_SQL}(
    $propertyId: String!
    $emailAddress: String!
    $cardNumber: String!
    $smartCardId: String!
  ) {
    ${UPDATE_CARD_CREDENTIALS_SQL}(
      input: {
        propertyId: $propertyId
        emailAddress: $emailAddress
        cardNumber: $cardNumber
        smartCardId: $smartCardId
      }
    ) {
      cardNumber
      smartCardCredential {
        id
        badgeNumber
      }
    }
  }
  `;

export const ADD_REMOTELOCK_DEVICES_TO_PROPERTIES = gql`
  mutation ${ADD_REMOTELOCK_DEVICES_TO_PROPERTIES_GQL}(
    $remotelockDeviceAssignments: [AddRemoteLockDeviceAssignment]!
  ) {
    ${ADD_REMOTELOCK_DEVICES_TO_PROPERTIES_GQL}(
      input: { remotelockDeviceAssignments: $remotelockDeviceAssignments }
    ) {
      count
    }
  }
`;

export const REMOVE_REMOTELOCK_DEVICES_TO_PROPERTIES = gql`
  mutation ${REMOVE_REMOTELOCK_DEVICES_TO_PROPERTIES_GQL}(
    $remotelockDeviceRemovals: [RemoveRemoteLockDeviceAssignment]!
  ) {
    ${REMOVE_REMOTELOCK_DEVICES_TO_PROPERTIES_GQL}(
      input: { remotelockDeviceRemovals: $remotelockDeviceRemovals }
    ) {
      recordIds
    }
  }
`;

export const UPDATE_USER_PROPERTY_NOTIFICATION_SETTING = gql`
  mutation updateUserPropertyNotificationSetting(
    $userId: String!
    $alarmType: ClimateAlarmType!
    $notificationMethod: NotificationMethod!
    $enabled: Boolean!
    $propertyId: String!
  ) {
    updateUserNotificationSetting(
      input: {
        userId: $userId
        alarmType: $alarmType
        notificationMethod: $notificationMethod
        enabled: $enabled
        propertyId: $propertyId
      }
    ) {
      alarmNotificationSettings {
        alarmType
        notificationSettings {
          notificationMethod
          enabled
        }
      }
    }
  }
`;
