import { IIntegrationsCard } from "../../interfaces/interfaces";
import { quantityDisplay } from "../../utils/utils";

const IntegrationCard: React.FC<{
  data: IIntegrationsCard;
}> = ({ data }) => {
  return (
    <div className="brilliant-card-container">
      <div className="brilliant-card-description">
        <div className="unit-title hidden-name">{data.name}</div>
        <div className="brilliant-card-description-id unit-description-small flex-display uppercase">
          {`${data.numDevices} ${quantityDisplay(data.numDevices, "DEVICE")}`}
        </div>
      </div>
    </div>
  );
};
export default IntegrationCard;
