import { ReactComponent as OneGang } from "../../img/Devices/1Gang.svg";
import { ReactComponent as TwoGang } from "../../img/Devices/2Gang.svg";
import { ReactComponent as ThreeGang } from "../../img/Devices/3Gang.svg";
import { ReactComponent as FourGang } from "../../img/Devices/4Gang.svg";
import { ReactComponent as UnknownControl } from "../../img/Devices/UnknownControl.svg";
import { IBrilliantControlsCard } from "../../interfaces/interfaces";

import BrilliantDeviceCard from "./BrilliantDeviceCard";

const gangImage = (gangNumber: number, isReconfiguring: boolean) => {
  const iconStyle = isReconfiguring
    ? "brilliant-card-img-left brilliant-card-img-left-fill disabled"
    : "brilliant-card-img-left brilliant-card-img-left-fill";

  if (gangNumber === 1) {
    return <OneGang title="1-Switch Control" className={iconStyle} />;
  }
  if (gangNumber === 2) {
    return <TwoGang title="2-Switch Control" className={iconStyle} />;
  }
  if (gangNumber === 3) {
    return <ThreeGang title="3-Switch Control" className={iconStyle} />;
  }
  if (gangNumber === 4) {
    return <FourGang title="4-Switch Control" className={iconStyle} />;
  }
  return <UnknownControl title="Unknown control" className={iconStyle} />;
};

const BrilliantControlCard: React.FC<{
  data: IBrilliantControlsCard;
}> = ({ data }) => {
  return (
    <BrilliantDeviceCard
      id={data.id}
      name={data.name}
      description={`${data.numGangs} Switch Control`}
      isReconfiguring={data.isReconfiguring}
      icon={gangImage(data.numGangs, data.isReconfiguring)}
    />
  );
};
export default BrilliantControlCard;
